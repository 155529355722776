import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import top from ".././assets/top.png"
import { Box } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';



const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <div className="global-wrapper" data-is-root-path={isHomePage} style={{ backgroundColor: "#ffffffee" }} >
        <header className="global-header">
          <Box style={{ textDecoration: "none", width: "100%", textAlign: "end" }}>
            <a href="https://tb-works.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", marginInlineEnd: "20px" }}>公式サイトはこちら <OpenInNewIcon fontSize="small" /></a>
          </Box>

          {isHomePage ? (
            <Box style={{ position: "relative", textAlign: "center" }}>
              <img src={top} alt="top" />
              <Box style={{ position: "absolute", bottom: "10px", width: "100%" }}>
                <h1 className="main-heading" style={{ color: "#404040", textShadow: "1px 1px 0 #FFF, -1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 0px 1px 0 #FFF, 0-1px 0 #FFF, -1px 0 0 #FFF, 1px 0 0 #FFF", }}>
                  <Link to="/">TbWorks<br />TechBlog</Link>
                </h1>
              </Box>
            </Box>

          ) : (
            <Link className="header-link-home" to="/">
              {title}
            </Link>
          )}

        </header>

        <main>{children}</main>

        <footer>
          copyrighy © {new Date().getFullYear()} tb-works.com
        </footer>
      </div>
    </div>

  )
}

export default Layout
